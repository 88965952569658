import React from 'react';
import logo from '../assets/isobar-logo-orange.png';
import { ArrowLeft } from './icons';

export default (props) => (
  <header>
    <div className="inner">
      <a href="https://isobar.com/au/en/">
        <img className="logo" src={logo} alt="Isobar logo" />
      </a>
      {props.linkToIndex ? (
        <a href="/" className="back">
          <ArrowLeft />
          Webinar Home
        </a>
      ) : (
        ''
      )}
    </div>
  </header>
);
